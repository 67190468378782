import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFound = () => (
  <Layout title="404">
    <SEO title="Not Found" />
    <div className="wrapper-404 container-lg">
      <h2>404 - PAGINA NIET GEVONDEN</h2>
      <Link
        to="/"
        className="aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
      >
        GA NAAR HOMEPAGINA
      </Link>
    </div>
  </Layout>
);

export default NotFound;
